const NewArrowIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 9H14.25M14.25 9L11.25 12M14.25 9L11.25 6"
        stroke="#F8F9FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewArrowIcon;
