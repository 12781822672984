import HomeNew from '~/pages/HomeNew';

export const meta = () => {
  return [
    {
      title:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    { tagName: 'link', rel: 'canonical', href: 'https://quizzly.ai' },
    { property: 'og:url', content: 'https://quizzly.ai' },
    {
      property: 'og:description',
      content:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    {
      property: 'og:title',
      content:
        "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@graph': [
          {
            '@type': 'Organization',
            '@id': 'https://quizzly.ai/#organization',
            name: 'Quizzly',
            url: 'https://quizzly.ai/',
            sameAs: [
              'https://www.facebook.com/Vuukle/',
              'https://www.linkedin.com/company/Vuukle',
              'https://twitter.com/Vuukle',
            ],
            logo: {
              '@type': 'ImageObject',
              '@id': '',
              inLanguage: 'en-US',
              url: 'https://quizzly.ai/#logo',
              contentUrl: 'https://image.vuukle.com/quizzly-favicon1.webp',
              width: '192',
              height: '192',
              caption: 'Quizzly Logo',
            },
            image: {
              '@id': '',
            },
          },
          {
            '@type': 'WebSite',
            '@id': 'https://quizzly.ai/#website',
            url: 'https://quizzly.ai/',
            name: 'Quizzly',
            description:
              "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            publisher: {
              '@id': 'https://quizzly.ai/#organization',
            },
            inLanguage: 'en-US',
          },
          {
            '@type': 'ImageObject',
            '@id': '',
            inLanguage: 'en-US',
            url: 'https://image.vuukle.com/quizzly-favicon1.webp',
            contentUrl: 'https://image.vuukle.com/quizzly-favicon1.webp',
            width: '192',
            height: '192',
          },
          {
            '@type': 'WebPage',
            '@id': 'https://quizzly.ai/#webpage',
            url: 'https://quizzly.ai/',
            name: "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            isPartOf: {
              '@id': 'https://quizzly.ai/#website',
            },
            about: {
              '@id': 'https://quizzly.ai/#organization',
            },
            primaryImageOfPage: {
              '@id': 'https://quizzly.ai/#primaryimage',
            },
            datePublished: '2023-09-19T13:03:22+00:00',
            dateModified: '2023-09-19T13:03:22+00:00',
            description:
              "Elevate Your Website's Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data for Publishers!",
            breadcrumb: {
              '@id': 'https://quizzly.ai/#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
              {
                '@type': 'ReadAction',
                target: ['https://quizzly.ai/'],
              },
            ],
          },
          {
            '@type': 'BreadcrumbList',
            '@id': 'https://quizzly.ai/#breadcrumb',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://quizzly.ai/',
              },
            ],
          },
        ],
      },
    },
  ];
};

export default HomeNew;
