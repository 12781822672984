import model1 from '/static/images/home/model1.png?url';
import model2 from '/static/images/home/model2.png?url';
import model3 from '/static/images/home/model3.png?url';

const AboutQuizzlyNew = () => {
  return (
    <div className="container-center">
      <div className="main-container container-center about-quizzly-new">
        <p className="about-quizzly-description-new">Trusted by Advertisers and Publishers Worldwide</p>
        <h5 className="about-quizzly-title-new">Numbers That Speak</h5>
        <div>
          <div className="about-quizzly-boxes-new">
            <h3>600</h3>
            <p>Publisher Partners</p>
            <span>Including Google-certified publishers.</span>
            <img src={model1} alt="model1" />
          </div>
          <div className="about-quizzly-boxes-new">
            <h3>30M</h3>
            <p>Surveys Annually</p>
            <span>Over 80,000 questions answered daily.</span>
            <img src={model2} alt="model2" />
          </div>
          <div className="about-quizzly-boxes-new">
            <h3>200</h3>
            <p>Countries Covered</p>
            <span>Global reach for universal insights</span>
            <img src={model3} alt="model3" />
          </div>
          <div className="about-quizzly-boxes-new">
            <p>
              US-Patented quiz format <span> that turns surveys into powerful marketing tools.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutQuizzlyNew;
