import React, { Suspense, useState } from 'react';
import 'lazysizes';

import '../styles/index.scss';
import HeaderNewHome from '~/components/HeaderNewHome.jsx';
import AboutQuizzlyNew from '~/components/AboutQuizzlyNew.jsx';
import WhyQuizzlyNew from '~/components/WhyQuizzlyNew.jsx';

const OurClientsNew = React.lazy(() => import('../components/OurClientsNew'));
const AdvertisersPublishers = React.lazy(() => import('../components/AdvertisersPublishers'));
const FooterNewHome = React.lazy(() => import('../components/FooterNewHome'));
const Modal = React.lazy(() => import('../components/Modal'));

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Suspense>
        <div id="newHome">
          <HeaderNewHome setModalIsOpen={setModalIsOpen} page={''} />
          <AboutQuizzlyNew />
          <WhyQuizzlyNew />
          <OurClientsNew />
          <AdvertisersPublishers />
          <FooterNewHome setModalIsOpen={setModalIsOpen} />
          <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </div>
      </Suspense>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        data-domain-script="c9f19c12-39ba-450a-b87a-fad3537b0b50"
        defer
      ></script>
    </>
  );
};

export default Home;
