import whyQuizzly from '/static/images/home/why-quizzly.png?url';
import whyQuizzlyM from '/static/images/home/why-quizzly-m.png?url';

const WhyQuizzlyNew = () => {
  return (
    <div className="container-center why-quizzly">
      <div className="main-container container-center flex-row justify-content-between">
        <div className="d-flex justify-content-between responsive-box flex-column">
          <p className="azure-p">Why Quizzly?</p>
          <h5 className="white-h5">Turn Engagement Into Insights, and Insights Into Action.</h5>
          <span className="white-span">Quizzly combines patented survey technology with contextual quizzes to help advertisers and publishers uncover what resonates with their audiences. Whether it’s driving conversions, measuring brand impact, or refining your strategy, Quizzly delivers real performance marketing results</span>
          <button className="top-button">Explore Our Patented Format</button>
        </div>
        <img src={whyQuizzly} alt="why-quizzly" className="d-m-none" width={540} />
        <img src={whyQuizzlyM} alt="why-quizzly" className="d-none" width={540} />
      </div>
    </div>
  );
};

export default WhyQuizzlyNew;
